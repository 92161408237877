<template>
  <el-card shadow="hover" class="mallManagement x-card-title">
    <div slot="header" class="clearfix" style="line-height: 32px;">
      <span>创建一件代发商城</span>
      <!-- <el-button style="float: right;" size="small" @click="() => { $router.go(-1); }">返回</el-button> -->
    </div>
    <div class="container">
      <div class="form">
        <div class="title">基本信息</div>
        <div class="content">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm">
            <el-form-item label="商城名称" prop="shop_name">
              <el-input v-model="ruleForm.shop_name" @input="genDomainNameCard"></el-input>
            </el-form-item>
            <el-form-item label="站点域名" prop="domain_name">
              <el-input v-model.trim="ruleForm.domain_name"
                onkeyup="value = value.split('-').join('').split('！').join('').split(' ').join('')">
                <template slot="prepend">https://</template>
                <template slot="append">{{ settingUrl }}</template>
              </el-input>
            </el-form-item>
            <el-form-item label="公司名称" prop="company_name">
              <el-input maxlength="50" v-model="ruleForm.company_name"></el-input>
            </el-form-item>
            <el-form-item label="业务员名称" prop="salesman_name">
              <el-input maxlength="50" v-model="ruleForm.salesman_name"></el-input>
            </el-form-item>
            <el-form-item label="站点负责人" prop="link_name">
              <el-input v-model="ruleForm.link_name"></el-input>
            </el-form-item>

            <el-form-item label="负责人联系方式" prop="link_phone">
              <el-input v-model="ruleForm.link_phone"></el-input>
            </el-form-item>

            <el-form-item label="站点账号" prop="mobile">
              <template>
                <el-input type="text" v-model="ruleForm.mobile"></el-input>
                <el-input type="text" style="position: fixed; bottom: -9999999px"></el-input>
              </template>
            </el-form-item>

            <el-form-item label="站点初始密码" prop="password">
              <el-input type="password" style="position: fixed; bottom: -9999999px"></el-input>
              <el-input type="password" v-model="ruleForm.password"></el-input>
            </el-form-item>

            <el-form-item label="站点状态" prop="shop_disable">
              <el-radio-group v-model="ruleForm.shop_disable">
                <el-radio label="OPEN">启用</el-radio>
                <el-radio label="CLOSED">禁用</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="是否需要资质认证" prop="qualification_certification_required">
              <el-radio-group v-model="ruleForm.qualification_certification_required">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="商城备注信息" prop="shop_remark">
              <el-input type="textarea" :rows="2" v-model="ruleForm.shop_remark"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" style="color: #fff" @click="prevStep()">取消</el-button>
              <el-button type="primary" style="color: #fff" @click="nextStep()">确认创建</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
import md5 from "js-md5";
import * as API_MallManageMent from "@/api/mallManagement";
import {
  validatePhone,
  validateLowerCase,
  validateAlphabetsAndNumber
} from "@/utils/validate";
import { pinyin } from "pinyin-pro";

const phoneValidator = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入联系方式"));
  }
  if (value.includes(" ")) {
    callback(new Error("输入不能包含空格"));
  }
  if (!validatePhone(value)) {
    callback(new Error("请输入正确的手机号码"));
  }
  callback();
};
const phoneValidator1 = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入站点账号"));
  }
  if (value.includes(" ")) {
    callback(new Error("输入不能包含空格"));
  }
  if (
    !validateAlphabetsAndNumber(value) ||
    value.length < 6 ||
    value.length > 20
  ) {
    callback(
      new Error("最少6位，最多20位。只能是数字、字母，字母区分大小写。")
    );
  }
  callback();
};
const lowerCaseValidator = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请补全网址"));
  }
  if (/^[a-z0-9]+$/.test(value) == false) {
    callback(new Error("请输入数字和小写英文字母"));
  }
  // if (!validateLowerCase(value)) {
  //   callback(new Error("请输入小写字母"));
  // }
  if (value && value.length > 15) {
    callback(new Error("输入的最大长度为15个字符"));
  }
  API_MallManageMent.checkDomainName(value).then(res => {
    if (res == 1) {
      callback(new Error("该站点域名已存在，请重新输入！"))
    } else {
      callback()
    }
  })
};

export default {
  name: "doubleSelectionEdit",
  data () {
    return {
      activeTabName: "doubleSelection",
      ruleForm: {
        shop_name: "",
        mall_type: "7", // 商城类型1.积分商城（javashop遗留，未用到）;2现金商城;3,套餐卡商城;5双选商城;7一件代发商城
        domain_name: "",
        company_name: '',
        salesman_name: '',
        link_name: "",
        link_phone: "",
        mobile: "",
        password: "",
        integer: "2",
        shop_disable: "OPEN",
        qualification_certification_required: 1,//是否需要资质认证（一件代发商城用） 0否 1是
        template: 1,
        shop_remark: "",
      },
      rules: {
        shop_name: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请输入商城名称"));
              }
              if (value.includes(" ")) {
                callback(new Error("输入不能包含空格"));
              }
              if (!/[A-Za-z0-9\u4e00-\u9fa5]$/.test(value)) {
                callback(new Error("请输入中英文商城名称"));
              }
              callback();
            },
            trigger: "blur"
          },
          {
            min: 1,
            max: 20,
            message: "长度在 1 到 20 个字符",
            trigger: "blur"
          }
        ],
        domain_name: [
          {
            required: true,
            validator: lowerCaseValidator,
            trigger: "blur"
          }
        ],
        link_name: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请输入负责人名称"));
              }
              if (value.includes(" ")) {
                callback(new Error("输入不能包含空格"));
              }
              if (!/^[\u4e00-\u9fa5]*$/.test(value)) {
                callback(new Error("请输入正确的负责人名称"));
              }
              callback();
            },
            trigger: "blur"
          },
          {
            min: 1,
            max: 20,
            message: "长度在 1 到 20 个字符",
            trigger: "blur"
          }
        ],
        link_phone: [
          {
            required: true,
            validator: phoneValidator,
            trigger: "blur"
          }
        ],
        mobile: [
          {
            required: true,
            validator: phoneValidator1,
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请输入站点初始密码"));
              }
              if (value.includes(" ")) {
                callback(new Error("输入不能包含空格"));
              }
              callback();
            },
            trigger: "blur"
          },
          {
            min: 6,
            max: 20,
            message: "长度在 6 到 20 个字符",
            trigger: "blur"
          }
        ]
      },
      loading: false,
    };
  },

  mounted () {
    this.activeTabName =
      this.$route.query.type === 2 ? "comboCardShop2" : "comboCardShop";
  },
  computed: {
    settingUrl () {
      if (this.$url.indexOf("fcshoptwo") !== -1) {//开发环境
        return '.testtwooneshopping.sshlqf.com/'
      } else if (this.$url.indexOf("m.fcshop") !== -1) {//测试环境
        return '.testoneshopping.sshlqf.com/'
      } else {//正式环境
        return '.oneshopping.sshlqf.com/'
      }
    }
  },
  methods: {
    prevStep () {
      // 上一步操作
      this.$router.push({ name: "shopManagement", query: { combo: 4 } });
    },
    nextStep () {
      if (this.loading) return;
      this.loading = true;
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.loading = false;
          // 所有表单校验通过  进行验证  执行下一步不
          // this.genDomainName();
          const data = JSON.parse(JSON.stringify(this.ruleForm));
          data.domain_name = `${data.domain_name}`;
          data.password = md5(data.password);
          data.mall_type = 7;
          console.log(data);
          API_MallManageMent.addChildShop(data)
            .then(res => {
              this.$router.push({
                name: "shopManagement",
                query: { combo: 4 }
              });
            })
            .catch(() => { this.loading = false; });
        }
      });
    },
    genDomainNameCard () {
      if (this.ruleForm.shop_name.trim()) {
        this.ruleForm.domain_name =
          pinyin(this.ruleForm.shop_name, {
            toneType: "none",
            type: "array"
          })
            .map(v => v.substring(0, 1))
            .join("") + Math.round(Math.random() * 10000);
        this.ruleForm.domain_name = this.ruleForm.domain_name
          .split("-")
          .join("")
          .split("！")
          .join("")
          .split("（")
          .join("")
          .split("）")
          .join("");
      } else {
        this.ruleForm.domain_name = "";
      }
      if (this.activeTabName !== "cashShop") {
        this.ruleForm.domain_name = this.ruleForm.domain_name;
      }
      this.ruleForm.domain_name = this.ruleForm.domain_name.toLowerCase();
    },
    genDomainName () {
      if (this.ruleForm.shop_name.trim()) {
        this.ruleForm.domain_name =
          pinyin(this.ruleForm.shop_name, {
            toneType: "none",
            type: "array"
          })
            .map(v => v.substring(0, 1))
            .join("") + Math.round(Math.random() * 10000);
      } else {
        this.ruleForm.domain_name = "";
      }
      this.ruleForm.domain_name = this.ruleForm.domain_name;
      this.ruleForm.domain_name = this.ruleForm.domain_name.toLowerCase();
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  .form {
    width: 80%;
    padding: 20px 30px;
    margin: 20px auto;

    .title {
      font-weight: bolder;
      font-size: 22px;
      text-align: center;
    }

    .content {
      width: 60%;
      margin: 0 auto;
      padding: 10px 0;

      /deep/ .el-form-item {
        margin-bottom: 35px;
      }
    }
  }
}
</style>